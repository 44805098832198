
<template>
	<div class="about">
		<b-container>
			<base-page-title image="bg-about-title">
				Conheça a
				<br />
				Wellvin
			</base-page-title>
			<div class="about-content">
				<h2 class="about-content-title">
					“...priorizamos o bom atendimento, a transparência
					<br />e a ética em todos os relacionamentos.”
				</h2>
				<div class="about-content-text">
					<p>
						Há duas décadas no mercado, a Wellvin trabalha com cuidado e responsabilidade para oferecer sempre o melhor
						produto aos nossos clientes e parceiros.
					</p>
					<p>
						Por meio da seleção das matérias-primas da mais alta qualidade e do desenvolvimento e produção de linhas que
						suprem as necessidades alimentícias do mercado, nos colocamos em um patamar de excelência e estamos sempre
						em busca de maneiras mais práticas e eficientes de atingir nossos objetivos.
					</p>
					<p>
						Com uma postura focada em criar laços duradouros com nossos clientes, priorizamos o bom atendimento, a
						transparência e a ética em todos os relacionamentos.
					</p>
				</div>
			</div>
		</b-container>

		<div class="about-gallery">
			<h4>Galeria</h4>
			<div class="about-gallery-box">
				<b-container class="about-gallery-content">
					<carousel :items="1" :nav="false" :dots="false" class="about-gallery-carousel">
						<template #prev>
							<span class="nav-btn nav-btn-left">
								<i class="las la-angle-left"></i>
							</span>
						</template>
						<template #next>
							<span class="nav-btn nav-btn-right">
								<i class="las la-angle-right"></i>
							</span>
						</template>
						<img v-for="item in 13" :src="getImage(item)" :key="item" />
					</carousel>
				</b-container>
			</div>
		</div>
	</div>
</template>

<script>
import BasePageTitle from '../components/BasePageTitle.vue'
export default {
	components: { BasePageTitle },
	data: () => ({}),
	methods: {
		getImage: item => {
			const image = require(`@/assets/images/galeria/galeria (${item}).jpg`)
			return image
		}
	}
}
</script>

<style lang="scss">
.about-gallery {
	text-align: center;
	margin-top: 12rem;
}

.about-content-title {
	margin: 6rem auto;
	margin-top: 10rem;
	max-width: 76rem;
	text-align: center;
}

.about-gallery-box {
	position: relative;
	.about-gallery-content {
	}
	.about-gallery-carousel {
		max-width: 94rem;
		margin: 0 auto;
		position: relative;
	}

	&:before {
		content: '';
		background: #000;
		height: 50%;
		top: 50%;
		width: 100%;
		position: absolute;
		left: 0;
		transform: translateY(-50%);
	}

	.nav-btn {
		position: absolute;
		top: 50%;
		font-size: 3rem;
		transform: translateY(-4rem);
		z-index: 99;
		cursor: pointer;
		color: #fff;
		background: $primary;
		width: 5rem;
		height: 5rem;
		border-radius: 100%;
		display: flex;
		align-items: center;
		justify-content: center;

		&:hover {
			color: $primary;
		}
	}
	.nav-btn-left {
		left: -7rem;
	}
	.nav-btn-right {
		right: -7rem;
	}
}

.about-content {
	margin: 0 auto;

	.about-content-text {
		max-width: 60rem;
		margin: 0 auto;
		p {
			margin-bottom: 4rem;
		}
	}
}
</style>
